import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Menu from "../general-components/menu/menu";
import classNames from "classnames";
import HeaderMetaLink from "./components/header-meta-link";

const MobileNavigation = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const lockBody = () => {
    document.body.style.overflow = "hidden";
  };

  const unlockBody = () => {
    document.body.style.overflow = "auto";
  };

  return (
    <div className="d-block d-lg-none">
      <div className="mobile-header">
        <div className="container">
          <div className="logo-wrapper">
            <Link to="/">
              <img src="/dr-malek-logo.png" alt="" />
            </Link>
          </div>
          <div className="hamburger-wrapper">
            <a
              role="button"
              onClick={() =>
                setMobileMenuOpen((prev) => {
                  (prev) ? unlockBody() : lockBody();
                  return !prev;
                })
              }
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <rect x="3" y="5" width="26" height="2" fill="black" />
                <rect x="3" y="15" width="26" height="2" fill="black" />
                <rect x="3" y="25" width="26" height="2" fill="black" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div
        className={classNames({
          "mobile-menu-wrapper": true,
          open: mobileMenuOpen,
        })}
      >
        <div className="container main-navigation">
          <nav>
            <Menu
              items={props.mainMenuQuery.menuByName.links}
              onClick={() => {
                setMobileMenuOpen(false);
                unlockBody();
              }}
            />
          </nav>
        </div>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="header-meta-text"
              dangerouslySetInnerHTML={{
                __html: props.headerMetaConfig?.fieldMetaLine.processed,
              }}
            />
            <div className="d-flex social">
              {props.headerMetaConfig?.fieldPhone && (
                <HeaderMetaLink
                  url={props.headerMetaConfig.fieldPhone.url}
                  type="phone"
                />
              )}
              {props.headerMetaConfig?.fieldContact && (
                <HeaderMetaLink
                  url={props.headerMetaConfig.fieldContact.url}
                  type="contact"
                />
              )}
              {props.headerMetaConfig?.fieldSupport && (
                <HeaderMetaLink
                  url={props.headerMetaConfig.fieldSupport.url}
                  type="support"
                />
              )}
              {props.headerMetaConfig?.fieldLogin && (
                <HeaderMetaLink
                  url={props.headerMetaConfig.fieldLogin.url}
                  type="login"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MobileNavigation.propTypes = {
  mainMenuQuery: PropTypes.object,
};

export default MobileNavigation;
