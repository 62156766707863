import React from "react";
import PropTypes from "prop-types";
import ImageTeaser, { imageTeaserPropTypes } from "./components/image-teaser";

const ParagraphImageTeaserList = ({ content }) => {
  return (
    <section className="paragraph paragraph-image-teaser-list">
      <div className="container">
        <div className="row">
          {content.fieldTeaser.map((item, i) => (
            <div key={i} className="col-12 col-md-6 col-xl-4">
              <ImageTeaser content={item.entity} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphImageTeaserList.propTypes = {
    content: PropTypes.shape({
        fieldTeaser: PropTypes.arrayOf(PropTypes.shape({
            entity: PropTypes.shape(imageTeaserPropTypes)
        }))
    })
};

export default ParagraphImageTeaserList;
