import React from "react";
import PropTypes from "prop-types";
import { Edit32 } from "@carbon/icons-react";

const EditButton = (props) => {
  if (props.adminApp) {
    return (
      <a
        href={
          props.customLink ||
          `/node/${props.entityId}/edit?destination=${props.destinationRoute}`
        }
        className="admin-link admin-edit-link"
      >
        <Edit32 />
      </a>
    );
  }

  return false;
};

EditButton.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  customLink: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  destinationRoute: PropTypes.string.isRequired,
};

export default EditButton;
