import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Link, { LinkPropType } from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphCallToAction = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-call-to-action": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {content.fieldLabel && (
              <div className="banner">{content.fieldLabel}</div>
            )}
            <div className="content text">
              <h2>{content.fieldTitle}</h2>
              <p>{content.fieldTeasertext}</p>
              <ErrorBoundary>
                <Link
                  className="btn btn-primary"
                  link={content.fieldCallToAction}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCallToAction.propTypes = {
  content: PropTypes.shape({
    fieldCallToAction: LinkPropType,
  }),
};

export default ParagraphCallToAction;
