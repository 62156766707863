import React from "react";
import PropTypes from "prop-types";

const ParagraphTabs = ({ content }) => {
  return (
    <section className="paragraph paragraph-tabs">
      <div className="container">
        <div className="row">
          {content.fieldTabs.map((tab, i) => (
            <div className="col-12 col-md-6 col-lg-4 tab-item-wrapper" key={i}>
              {!!tab.entity.fieldIcon?.length && (
                <div className="icon-wrapper">
                  <img
                    src={tab.entity.fieldIcon[0].entity.fieldMediaFile?.entity.url}
                    alt={tab.entity.fieldTitle}
                  />
                </div>
              )}
              <h3>{tab.entity.fieldTitle}</h3>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: tab.entity.fieldText.processed,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphTabs.propTypes = {
  content: PropTypes.shape({
    fieldTabs: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitle: PropTypes.string,
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
          fieldIcon: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaFile: PropTypes.shape({
                url: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphTabs;
