import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const MegaMenu = ({ open, menu, onClose }) => {
  const megaMenuRef = useRef();

  return (
    <div
      ref={megaMenuRef}
      className={classNames({
        "mega-menu": true,
        active: open,
      })}
      onMouseLeave={onClose}
    >
      <div className="container">
        <nav>
          <ul className="mega-menu-list">
            {menu.map((item, i) => (
              <li key={i} className="mega-menu-item">
                <Link to={item.link}>
                  {item.icon && <img className="icon" src={item.icon} alt="" />}
                  {item.title}
                  <p>{item.text}</p>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

MegaMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  menu: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default MegaMenu;
