import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Image from "../../../image/image";

const ParagraphContentSlider = ({ content }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef();

  const sliderSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    afterChange: (i) => setSlideIndex(i),
  };

  const goToSlide = (e, index) => {
    e.preventDefault();
    sliderRef.current.slickGoTo(index);
    setSlideIndex(index);
  };

  return (
    <section className="paragraph paragraph-content-slider">
      <div className="container">
        <div className="slider-wrapper">
          <button
            className="slider-nav-button prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            ←
          </button>
          <Slider {...sliderSettings} ref={sliderRef}>
            {content.fieldSlides.map((slide, i) => (
              <div className="content-slide" key={i}>
                <div className="content-slide-inner">
                  <Image
                    data={slide.entity.fieldImage.entity.fieldMediaImage}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: slide.entity.fieldText.processed,
                    }}
                    className="text"
                  />
                  <div className="icon-text">
                    {slide.entity.fieldIcons.map((icon, j) => (
                      <div className="icon-text-element" key={j}>
                        {icon.entity.fieldIcon.map((icon, j) => (
                          <div className="icon-wrapper" key={j}>
                            <img
                              src={icon.entity.fieldMediaFile.entity.url}
                              key={j}
                              alt={icon.entity.name}
                            />
                          </div>
                        ))}
                        <div className="text-wrapper">
                          <h4>{icon.entity.fieldTitle}</h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: icon.entity.fieldText.processed,
                            }}
                            className="text"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <button
            className="slider-nav-button next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            →
          </button>
        </div>
        <div className="container upper-bound">
          <ul className="dots">
            {content.fieldSlides.map((item, i) => (
              <li key={i}>
                <button
                  className={`${slideIndex === i ? "active" : ""}`}
                  onClick={(e) => goToSlide(e, i)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

ParagraphContentSlider.propTypes = {
  content: PropTypes.shape({
    fieldSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldImage: PropTypes.object,
        }),
      })
    ),
  }),
};

export default ParagraphContentSlider;
