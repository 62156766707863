import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphGoogleMaps = ({ content }) => {
  const [showMap, setShowMap] = useState(false);

  return (
    <section className="paragraph paragraph-google-maps">
      <div
        className={classNames({
          consent: true,
          "d-none": showMap,
        })}
      >
        <p>
          Beim Anzeigen der Google-Maps-Karte werden personenbezogene Daten an Google gesendet.
        </p>
        <div className="action-wrapper">
          <button
            onClick={() => setShowMap(true)}
            className="btn btn-secondary"
          >
            Karte anzeigen
          </button>
        </div>
      </div>
      <div
        className="google-maps-wrapper"
        dangerouslySetInnerHTML={{
          __html: showMap ? content.fieldEmbedCode : "",
        }}
      ></div>
    </section>
  );
};

ParagraphGoogleMaps.propTypes = {};

export default ParagraphGoogleMaps;
