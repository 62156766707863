import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const HeaderMetaLink = ({ url, type }) => {
  const icon = <span className={`icon icon-${type}`} />;

  if (url.routed) return <Link to={url.path}>{icon}</Link>;
  return <a title={type} href={url.path}>{icon}</a>;
};

HeaderMetaLink.propTypes = {
  url: PropTypes.shape({
    path: PropTypes.string.isRequired,
    routed: PropTypes.bool.isRequired,
  }),
  type: PropTypes.oneOf(["phone", "contact", "support", "login"]).isRequired,
};

export default HeaderMetaLink;
