import { graphql } from "@apollo/client/react/hoc";
import React from "react";
import PropTypes from "prop-types";
import NodesQuery from "./query-nodes.graphql";
import TeaserSolution from "../../../teaser-base/solution/teaser-solution";
import TeaserProduct from "../../../teaser-base/product/teaser-product";
import TeaserJob from "../../../teaser-base/job/teaser-job";

const ParagraphSimpleTeaserList = ({ content, ...props }) => {
  if (props.nodes?.loading) return;

  const nodes =
    props.nodes?.nodeQuery.entities ||
    content.fieldContents.map((node) => node.entity);

  const teasers = nodes.map((node) => {
    switch (node.entityBundle) {
      case "solution":
        return <TeaserSolution content={node} />;
      case "product":
        return <TeaserProduct content={node} />;
      case "job":
        return <TeaserJob content={node} />;
    }
  });

  if (!teasers.length) return;

  return (
    <section
      className={`paragraph paragraph-simple-teaser-list type-${props.type}`}
    >
      <div className="container">
        <div className="row">
          {teasers.map((teaser, i) => (
            <div className="col-12 col-md-6" key={i}>
              {teaser}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphSimpleTeaserList.propTypes = {
  content: PropTypes.shape({
    fieldAuto: PropTypes.bool,
    fieldContents: PropTypes.array,
  }),
  type: PropTypes.oneOf(["product", "solution", "job"]),
  nodes: PropTypes.object,
};

export default graphql(NodesQuery, {
  name: "nodes",
  skip: (props) => !props.content.fieldAuto,
  options: (props) => ({
    variables: {
      type: [props.type],
    },
  }),
})(ParagraphSimpleTeaserList);
