import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowDownRight32 } from "@carbon/icons-react";

import Link, { LinkPropType } from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import ClickSuggestionsItem, {
  ClickSuggestionsBoxPropTypes,
} from "./components/click-suggestions-box";
import ClickSuggestionsColumn from "./components/click-suggestions-column";

const ParagraphClickSuggestions = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-click-suggestions": true,
  });

  let innerContent = [];
  if (content.fieldAppearance === "boxes") {
    innerContent = content.fieldBoxes;
  }
  if (content.fieldAppearance === "detached") {
    innerContent = content.fieldColumns;
  }

  const layout = (() => {
    if (innerContent.length === 1) {
      return "col-lg-6 offset-lg-3"
    }

    if (innerContent.length % 2 === 0) {
      return "col-md-6 col-lg-3 offset-lg-2"
    }

    if (innerContent.length % 3 === 0) {
      return "col-md-4"
    }
  })();

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          {innerContent.map((item, i) => (
            <div className={`col-12 ${layout}`} key={i}>
              {content.fieldAppearance === "boxes" && (
                <ClickSuggestionsItem content={item} />
              )}
              {content.fieldAppearance === "detached" && (
                <ClickSuggestionsColumn content={item} />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphClickSuggestions.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldClickSuggestions: PropTypes.arrayOf(
      PropTypes.shape(ClickSuggestionsBoxPropTypes)
    ),
  }),
};

export default ParagraphClickSuggestions;
