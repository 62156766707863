import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";
import { self } from "../../../../config";

const CustomArrow = ({ onClick, className, isNext }) => (
  <img
    src={`${self}/arrow-blue.svg`}
    alt={isNext ? "Next" : "Prev"}
    className={className}
    onClick={onClick}
  />
);

CustomArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isNext: PropTypes.bool,
};

const ParagraphTeaserSlider = ({ content }) => {
  const sliderSettings = {
    arrows: true,
    dots: true,
    nextArrow: <CustomArrow isNext width={48} height={48} viewBox="0 0 32 24" />,
    prevArrow: <CustomArrow width={48} height={48} viewBox="0 0 32 24" />,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-slider": true,
  });

  return (
    <section className={sectionClassNames}>
      <ErrorBoundary>
        <Slider {...sliderSettings}>
          {content.fieldTeaserSlides.map((item, index) => {
            return (
              <div key={index}>
                <article className="container">
                  <div className="row">
                    <ErrorBoundary>
                      {item.entity.fieldImage.entity.fieldMediaImage && (
                        <Image
                          className="background-image"
                          data={item.entity.fieldImage.entity.fieldMediaImage}
                        />
                      )}
                    </ErrorBoundary>
                  </div>
                  <div className="row text">
                    {item.entity.fieldTeasertext && (
                      <div className="teaser-txt">
                        {item.entity.fieldTeasertext}
                      </div>
                    )}
                  </div>
                </article>
              </div>
            );
          })}
        </Slider>
      </ErrorBoundary>
    </section>
  );
};

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTeaserueberschrift: PropTypes.string.isRequired,
          fieldTeasertext: PropTypes.string,
          fieldImage: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldInhaltLink: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphTeaserSlider;
