import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import ErrorBoundary from "../../../../error-boundary";
import Link from "../../../link/link";
import { self } from "../../../../config";

const ParagraphIntroSlider = ({ content }) => {
  const sliderSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (i) => setSlideIndex(i),
  };

  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  const sectionClassNames = classNames({
    "paragraph paragraph-intro-slider": true,
  });

  const goToSlide = (e, index) => {
    e.preventDefault();
    sliderRef.current.slickGoTo(index);
    setSlideIndex(index);
  };

  return (
    <section className={sectionClassNames}>
      <img
        src={`${self}/Background_lichtstreifen.jpg`}
        alt="Lichtstreifen Hintergrund"
        className="bg-img"
      />
      <div className="container upper-bound">
        <ul className="dots">
          {content.fieldIntroslides.map((item, i) => (
            <li key={i}>
              <button
                className={`${slideIndex === i ? "active" : ""}`}
                onClick={(e) => goToSlide(e, i)}
              />
            </li>
          ))}
        </ul>
      </div>
      <ErrorBoundary>
        <Slider {...sliderSettings} ref={sliderRef}>
          {content.fieldIntroslides.map((item, index) => {
            return (
              <div key={index}>
                <article
                  className="container"
                  onClick={(e) => goToSlide(e, index)}
                >
                  <div className="row">
                    <div className="col-lg-7 col-12 text-part">
                      {item.entity.fieldTitel && (
                        <div className="headline">{item.entity.fieldTitel}</div>
                      )}
                      {item.entity.fieldUntertitel && (
                        <div className="underline">
                          {item.entity.fieldUntertitel}
                        </div>
                      )}
                      {item.entity.fieldCallToAction && (
                        <Link
                          className="btn btn-primary"
                          link={item.entity.fieldCallToAction}
                        />
                      )}
                    </div>
                    <div className="col-lg-5 vanished">
                      <ErrorBoundary>
                        {item.entity.fieldImage?.entity.fieldMediaFile && (
                          <img
                            className="background-image"
                            src={
                              item.entity.fieldImage.entity.fieldMediaFile
                                .entity.url
                            }
                          />
                        )}
                      </ErrorBoundary>
                    </div>
                  </div>
                </article>
              </div>
            );
          })}
        </Slider>
      </ErrorBoundary>
    </section>
  );
};

ParagraphIntroSlider.propTypes = {
  content: PropTypes.shape({
    fieldIntroslides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string.isRequired,
          fieldUntertietel: PropTypes.string,
          fieldImage: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldCallToAction: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
};
export default ParagraphIntroSlider;
