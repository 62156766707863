import React from "react";
import PropTypes from "prop-types";
import { LinkPropType } from "../../../../link/link";
import { Link } from "react-router-dom";

const ClickSuggestionsBox = ({ content }) => {
  const { fieldImage, fieldLink, fieldTeasertext, fieldTitle } = content.entity;

  return (
    <div className="click-suggestions-box">
      <Link to={fieldLink.url.path}>
        <div className="head">
          <img
            src={fieldImage.entity.fieldMediaImage.style.url}
            alt={fieldImage.entity.fieldMediaImage.alt}
          />
        </div>
        <div className="body">
          <h3>{fieldTitle}</h3>
          <p>{fieldTeasertext}</p>
        </div>
        <div className="footer">
          <div className="btn btn-secondary">
            <span>{fieldLink.title}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const ClickSuggestionsBoxPropTypes = {
  content: PropTypes.shape({
    entity: PropTypes.shape({
      fieldTitle: PropTypes.string.isRequired,
      fieldTeasertext: PropTypes.string.isRequired,
      fieldLink: LinkPropType,
      fieldImage: PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
            alt: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
};

ClickSuggestionsBox.propTypes = ClickSuggestionsBoxPropTypes;

export default ClickSuggestionsBox;
