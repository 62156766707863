import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import Menu from "../general-components/menu/menu";
import { Edit32 } from "@carbon/icons-react";
import EditButton from "../backend/edit-button";
import moment from "moment";

const PageFooter = (props) => {
  const adminApp = useSelector((state) => state.appStore.adminApp);

  if (!props.footerConfig && !props.mainMenu && !props.footerMenu) {
    return <LoadingIndicator />;
  }

  const mainMenuItems = props.mainMenu?.links;
  const footerMenuItems = props.footerMenu?.links;

  return (
    <>
      <section className="footer-main">
        <div className="container">
          <div className="footer-main-inner">
            <nav className="footer-nav-main">
              <EditButton
                adminApp={adminApp}
                customLink="/admin/structure/menu/manage/main"
              />
              <Menu items={mainMenuItems} />
            </nav>
          </div>
        </div>
      </section>
      <section className="footer-legal">
        <div className="container">
          <div className="footer-legal-inner">
            <nav className="footer-nav-legal">
              <Menu items={footerMenuItems} />
              <p>© {moment().format("YYYY")} {props.footerConfig?.fieldCopyright}</p>
            </nav>

            <div className="logo-wrapper">
              <img
                className="logo"
                src="/dr-malek-logo-white.png"
                alt="Dr. Malek Logo"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

PageFooter.propTypes = {
  footerConfig: PropTypes.shape({
    fieldFooterSitemapZeigen: PropTypes.bool,
    fieldCopyright: PropTypes.string.isRequired,
    fieldMenue: PropTypes.shape({
      targetId: PropTypes.string,
    }),
    fieldFacebook: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
  menu: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
};

export default PageFooter;
