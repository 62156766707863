import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <section>
      <div className="container">
        <h1>404</h1>
        <div className="text">
          <p>Die von Ihnen angeforderte Seite konnte nicht gefunden werden.</p>
          <p>
            <Link to="/">Zur Startseite</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Error404;
