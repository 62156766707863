import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { drupalFormat } from "../../config";
import { useSelector } from "react-redux";
require("twix");

const FieldTime = ({ date, locale = "" }) => {
  const { currentLanguage } = useSelector((reduxStore) => ({
    currentLanguage: locale || reduxStore.i18n.currentLanguage,
  }));

  const getDateObject = (value) => {
    const dateMoment = moment
      .utc(value, drupalFormat)
      .locale(currentLanguage)
      .local();
    return {
      moment: dateMoment,
      isMidnight: ["0000", "2359"].includes(dateMoment.format("HHmm")),
    };
  };

  const momentStart = getDateObject(date.value);
  const momentEnd = getDateObject(date.endValue);

  const isRange = date.value < date.endValue;

  let formattedDate;
  let dateArray;

  const formatSettings = (() => {
    switch (currentLanguage) {
      case "de":
        return {
          minuteFormat: "mm",
          hourFormat: "HH",
          dayFormat: "D.",
          monthFormat: "MMMM YYYY",
          dateTimeFormat: `D. MMMM YYYY${
            !momentStart.isMidnight ? ", HH:mm" : ""
          }`,
        };
      case "en":
      default:
        return {
          minuteFormat: "mm",
          hourFormat: "hh",
          dayFormat: "D",
          implicitMinutes: false,
          monthFormat: "MMMM YYYY",
          dateTimeFormat: `MMMM D, YYYY${
            !momentStart.isMidnight ? ", hh:mm A" : ""
          }`,
        };
    }
  })();

  if (isRange) {
    const allDay =
      momentStart.moment.format("HHmm") === "0000" &&
      momentEnd.moment.format("HHmm") === "2359";

    const range = moment.twix(momentStart.moment, momentEnd.moment, { allDay });
    formattedDate = range.format(formatSettings);
    dateArray = [
      {
        string: formattedDate.split(" - ")[0],
        timestamp: momentStart.moment.format(),
      },
      {
        string: formattedDate.split(" - ")[1],
        timestamp: momentEnd.moment.format(),
      },
    ];
    !dateArray[1].string && dateArray.pop(); // if it is not rendered as range (due to settings), delete the second item
  } else {
    formattedDate = momentStart.moment.format(formatSettings.dateTimeFormat);
    dateArray = [
      {
        string: formattedDate,
        timestamp: momentStart.moment.format(),
      },
    ];
  }

  console.log(dateArray);

  return (
    <div className="time-wrapper">
      {dateArray.map((item, i) => (
        <>
          <time dateTime={item.timestamp} key={item.timestamp}>
            {item.string}
          </time>
          {i + 1 < dateArray.length && " - "}
        </>
      ))}
    </div>
  );
};

FieldTime.propTypes = {
  /**
   * Use fieldDate as date prop.
   */
  date: PropTypes.shape({
    value: PropTypes.number,
    startDate: PropTypes.string,
    endValue: PropTypes.number,
    endDate: PropTypes.string,
  }),
  locale: PropTypes.string,
};

export default FieldTime;
