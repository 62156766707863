import React from "react";
import PropTypes from "prop-types";
import { LinkPropType } from "../../../../link/link";
import { Link } from "react-router-dom";

const ClickSuggestionsColumn = ({ content }) => {
  const { fieldLink, fieldTeasertext, fieldTitle } = content.entity;

  return (
    <div className="click-suggestions-column">
      <div className="body">
        <h3>{fieldTitle}</h3>
        <p>{fieldTeasertext}</p>
      </div>
      {fieldLink && (
        <div className="footer">
          <Link to={fieldLink.url.path} className="btn btn-secondary">
            {fieldLink.title}
          </Link>
        </div>
      )}
    </div>
  );
};

export const ClickSuggestionsColumnPropTypes = {
  content: PropTypes.shape({
    entity: PropTypes.shape({
      fieldTitle: PropTypes.string.isRequired,
      fieldTeasertext: PropTypes.string.isRequired,
      fieldLink: LinkPropType,
      fieldImage: PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
            alt: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
};

ClickSuggestionsColumn.propTypes = ClickSuggestionsColumnPropTypes;

export default ClickSuggestionsColumn;
