import React, { Component } from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "../../error-boundary";
import ParagraphsSwitch from "./paragraphs-switch";
import { Fade } from "react-awesome-reveal";

class ContentBase extends Component {
  render() {
    return (
      <section className={`content-wrap ${this.props.className}`}>
        {this.props.content.map((item, index) => (
          <ErrorBoundary key={index}>
            {
              // eslint-disable-next-line complexity
              (() => {
                let paragraphItem = item.entity;

                if (paragraphItem.entityBundle === "from_library") {
                  paragraphItem =
                    item.entity.fieldReusableParagraph.entity.paragraphs.entity;
                }

                return (
                  <Fade triggerOnce className={`fade-wrapper-${paragraphItem.entityBundle.replace("_", "-")}`}>
                    <ParagraphsSwitch
                      paragraphItem={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </Fade>
                );
              })()
            }
          </ErrorBoundary>
        ))}
      </section>
    );
  }
}

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
};

export default ContentBase;
