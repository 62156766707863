import classNames from "classnames";
import Image from "../../../image/image";
import React from "react";
import PropTypes from "prop-types";

const ParagraphImage = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-image": true,
  });
  const colClassNames = classNames({
    "col-4": content.fieldImageformat.entity.fieldFormat === "col-4",
    "col-6": content.fieldImageformat.entity.fieldFormat === "col-6",
    "col-8": content.fieldImageformat.entity.fieldFormat === "col-8",
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className={colClassNames}>
            {content.fieldBilder &&
              <Image data={content.fieldBilder.entity.fieldMediaImage}/>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphImage.propTypes = {
  content: PropTypes.shape({
    fieldImageformat: PropTypes.shape({
      entity: PropTypes.shape({
       fieldFormat: PropTypes.string
      }),
    }),
    fieldBilder: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape ({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        })
      })
    })
  }),
  sectionClassNames: PropTypes.string,
  colClassNames: PropTypes.string,
};

export default ParagraphImage;