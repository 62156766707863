import React, { useEffect, useState } from "react";
import SimpleNavigation from "./simple-navigation";
import PropTypes from "prop-types";
import useScrollInfo from "../hooks/useScrollInfo";
import classNames from "classnames";
import MobileNavigation from "./mobile-navigation";
import menuQuery from "../general-components/menu-query.graphql";
import { graphql } from "@apollo/client/react/hoc";

const SimpleHeader = (props) => {
  const scrollInfo = useScrollInfo();
  const [top, setTop] = useState(true);
  const [scrollingUp, setScrollingUp] = useState(false);

  useEffect(() => {
    if (scrollInfo.y >= 350) {
      setTop(false);
    } else {
      setTop(true);
    }

    if (scrollInfo.yDir < 0) {
      setScrollingUp(true);
    } else {
      setScrollingUp(false);
    }
  }, [scrollInfo]);
  
  if (props.mainMenuQuery.loading || !props.products) return;

  return (
    <header
      id="site-header"
      className={classNames({
        top: top,
        "scrolling-up": scrollingUp,
      })}
    >
      <SimpleNavigation
        products={props.products}
        headerMetaConfig={props.headerMetaConfig}
        mainMenuQuery={props.mainMenuQuery}
      />
      <MobileNavigation
        products={props.products}
        headerMetaConfig={props.headerMetaConfig}
        mainMenuQuery={props.mainMenuQuery}
      />
    </header>
  );
};

SimpleHeader.propTypes = {
  products: PropTypes.array.isRequired,
  headerMetaConfig: PropTypes.object.isRequired,
  mainMenuQuery: PropTypes.object.isRequired,
};

export default graphql(menuQuery, {
  options: (props) => ({
    variables: {
      name: "main",
      language: props.currentLanguage
        ? props.currentLanguage.toUpperCase()
        : "DE",
    },
  }),
  name: "mainMenuQuery",
})(SimpleHeader);
