import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import EditButton from "../../../backend/edit-button";
import { withRouter } from "react-router-dom";

const TeaserJob = ({ content, location }) => {
  const adminApp = useSelector((state) => state.appStore.adminApp);
  return (
    <article>
      <EditButton
        adminApp={adminApp}
        entityId={content.entityId}
        destinationRoute={location?.pathname}
      />
      <h3>{content.title}</h3>
      <div
        className="text"
        dangerouslySetInnerHTML={{ __html: content.fieldText.processed }}
      />
      {content.fieldFile && (
        <div className="text">
          <a
            href={content.fieldFile.entity.fieldMediaFile.entity.url}
            target="_blank"
            rel="noreferrer"
            className="download-link"
          >
            <FormattedMessage id="job_offer" /> {content.title}
          </a>
        </div>
      )}
    </article>
  );
};

export const teaserJobPropTypes = {
  entityId: PropTypes.string,
  title: PropTypes.string.isRequired,
  fieldFile: PropTypes.object,
  fieldText: PropTypes.shape({
    processed: PropTypes.string,
  }),
};

TeaserJob.propTypes = {
  content: PropTypes.shape(teaserJobPropTypes),
  location: PropTypes.object,
};

export default withRouter(TeaserJob);
