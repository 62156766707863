import React from "react";
import PropTypes from "prop-types";

const ParagraphIconText = ({ content }) => {
  const items = content.fieldItems.map((item) => item.entity);

  return (
    <section className="paragraph paragraph-icon-text">
      <div className="container">
        <div className="row">
          {items.map((item, i) => (
            <div className="col-12 col-md-9 col-lg-6 item" key={i}>
              <div className="icons">
                {item.fieldIcon.map((icon, j) => (
                  <div className="icon-wrapper" key={j}>
                    <img
                      src={icon.entity.fieldMediaFile.entity.url}
                      key={j}
                      alt={icon.entity.name}
                    />
                  </div>
                ))}
              </div>
              <div className="text">
                <h3>{item.fieldTitle}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: item.fieldText.processed }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphIconText.propTypes = {
  content: PropTypes.shape({
    fieldItems: PropTypes.arrayOf(
      PropTypes.shape({
        fieldIcon: PropTypes.arrayOf(
          PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaFile: PropTypes.object,
              name: PropTypes.string,
            }),
          })
        ),
        fieldTitle: PropTypes.string,
        fieldText: PropTypes.shape({
          processed: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ParagraphIconText;
