import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import EditButton from "../../../backend/edit-button";
import { useSelector } from "react-redux";

const TeaserSolution = ({ content, location }) => {
  const { fieldMediaImage: imgData, name: imgName } =
    content.fieldTeaserbild.entity;

  const adminApp = useSelector((state) => state.appStore.adminApp);

  return (
    <article className="node node-teaser teaser-solution">
      <EditButton
        adminApp={adminApp}
        entityId={content.entityId}
        destinationRoute={location?.pathname}
      />
      <Link to={content.entityUrl.path} className="card">
        <div className="image-overlay d-none d-lg-block">
          <img
            src={imgData.style.url}
            alt={imgData.alt || imgName}
            width={imgData.style.width}
            height={imgData.style.height}
          />
        </div>
        <div className="card-head">
          <div className="title-wrapper">
            <h3>{content.title}</h3>
          </div>
        </div>
        <div className="card-body">
          <div
            dangerouslySetInnerHTML={{
              __html: content.fieldTeasertext.processed,
            }}
          />
        </div>
        <div className="card-footer">
          <div className="btn btn-secondary">Mehr</div>
        </div>
      </Link>
    </article>
  );
};

TeaserSolution.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    entityUrl: PropTypes.object,
    title: PropTypes.string,
    fieldTeasertext: PropTypes.object,
    fieldTeaserbild: PropTypes.object,
  }),
  location: PropTypes.object,
};

export default withRouter(TeaserSolution);
