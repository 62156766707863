import React from "react";
import PropTypes from "prop-types";
import Image from "../../../image/image";

const ParagraphIntro = ({ content, nodeContent }) => {
  const imgData = content.fieldBackgroundImage?.entity.fieldMediaImage;
  const backgroundProperty = imgData
    ? {
        "--bg-img": `url(${imgData.style.url})`,
      }
    : {};

  let imageOverlay;

  switch (nodeContent?.entityBundle) {
    case "solution":
      imageOverlay = (
        <Image data={nodeContent?.fieldTeaserbild?.entity?.fieldMediaImage} />
      );
      break;
    case "product":
      imageOverlay = (
        <img
          src={nodeContent?.fieldIconCloud?.entity?.fieldMediaFile.entity.url}
        />
      );
      break;
  }
  nodeContent?.entityBundle === "solution" &&
    nodeContent?.fieldTeaserbild?.entity?.fieldMediaImage;

  const title = content.fieldTitle?.replace("®", "<sup>®</sup>");

  return (
    <section className="paragraph paragraph-intro" style={backgroundProperty}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            {content.fieldText && (
              <div
                dangerouslySetInnerHTML={{
                  __html: content.fieldText.processed,
                }}
                className="big"
              />
            )}
          </div>
          {imageOverlay && (
            <div className={`image-overlay d-none d-lg-block overlay-${nodeContent.entityBundle}`}>
              {imageOverlay}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphIntro.propTypes = {
  content: PropTypes.shape({
    fieldBackgroundImage: PropTypes.object,
    fieldTitle: PropTypes.string.isRequired,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }),
  nodeContent: PropTypes.object,
};

export default ParagraphIntro;
